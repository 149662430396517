import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbService, Breadcrumb } from 'angular-crumbs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { DataSharingService } from './data-sharing.service';
import { Webhook } from './interfaces/Webhook';
import { OrderActions } from './signalrActions/OrderActions';
import { environment } from 'src/environments/environment';
import { HubConnectionBuilder, HttpTransportType, HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Order } from './interfaces/IOrder/Order';
import { AuthService } from './services/AuthService';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class AppComponent implements OnInit {

  hubConnection: HubConnection;
  retryTimesHub = [0, 3000, 10000, 60000, 120000, 240000, 480000, 1000000];
  clientId: string;
  tokenClient: string;
  userLogged = false;
  webhook = new Webhook();
  lastPlaySound: number;

  constructor(private titleService: Title,
    private breadcrumbService: BreadcrumbService,
    private dataSharingService: DataSharingService,
    private authService: AuthService,
    private router: Router)
  { }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbChanged.subscribe(crumbs => {
      this.titleService.setTitle(this.createTitle(crumbs));
    });

    // Simula o recebimento de dados do webhook a cada 1 segundo
    this.tokenClient = localStorage.getItem('token');

    this.dataSharingService.getWebhookNotifyLogin().subscribe(data => {
      if (data) {
        this.tokenClient = localStorage.getItem('token');
        this.userLogged = data;
        this.webSocketOrder(this.tokenClient);
      }
    });

    setInterval(() => {

      if (this.userLogged || this.tokenClient != null)
      {
        if (this.tokenClient != null)
        {
          // verifica se a conexão está fechada e tenta reconectar
          if (!this.hubConnection || this.hubConnection.state == HubConnectionState.Disconnected)
          {
            this.webSocketOrder(this.tokenClient);
          }

          this.clientId = localStorage.getItem('client-id');
          this.webhook.clientId = this.clientId;
          this.webhook.token = this.tokenClient;

          if (this.webhook.NewOrder != null)
            this.webhook.Orders.push(this.webhook.NewOrder);

          // Atualiza os dados no serviço
          this.dataSharingService.updateWebhookData(this.webhook);

          // após enviar a ordem, limpa o objeto
          this.webhook.NewOrder = null;

          this.sendMessage(this.clientId);
        }
        else
        {
          this.tokenClient = localStorage.getItem('token');
        }
      }

    }, 1000);
  }

  onActivate(event){
    window.scroll(0,0);
  }

  private createTitle(routesCollection: Breadcrumb[]) {
    const title = 'Majoy';
    const titles = routesCollection.filter((route) => route.displayName);

    if (!titles.length) { return title; }

    const routeTitle = this.titlesToString(titles);
    return `${routeTitle} ${title}`;
  }

  private titlesToString(titles) {
    return titles.reduce((prev, curr) => {
      return `${curr.displayName} - ${prev}`;
    }, '');
  }

  webSocketOrder(token: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/hub/store-order?token=${token}`, {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => token
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: context => {
          const index = context.previousRetryCount < this.retryTimesHub.length ? context.previousRetryCount : this.retryTimesHub.length - 1;
          return this.retryTimesHub[index];
        }
      })
      .build();

      this.hubConnection.on("Health", (clientId : string) => {
        console.log(clientId);
      });

      this.hubConnection.on(OrderActions.ORDER_CREATED, (clientId : string, json: string) => {

        // verifica se a mensagem enviada está direcionada ao clientId do usuário logado
        if (this.clientId.toLocaleLowerCase() == clientId.toLocaleLowerCase())
        {
          // verifica se o último som foi tocado a mais de 15 segundos
          if (this.lastPlaySound == null || this.lastPlaySound < (new Date().getTime() - 15000))
          {
            // toca o som de novo pedido
            let audio = new Audio();
            audio.src = "../../../assets/audio/new-pedido.mp3";
            audio.load();
            audio.play();
            setTimeout(() => {
              audio.pause();
              this.lastPlaySound = new Date().getTime();
            }, 4000);
          }

          var order = JSON.parse(json) as Order;
          this.webhook.NewOrder = order;
        }

      });

      this.hubConnection.onreconnected(_ => _);

      this.hubConnection.start()
      .then(_ => { })
      .catch(_ => {
        this.userLogged = false;
        this.router.navigate(['/login']);
       });
  }

  public sendMessage(clientId: string) {

    console.log(this.hubConnection)

    this.hubConnection.invoke('Health', clientId)
      .catch(err => console.error(err));
  }

}
