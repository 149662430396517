import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs-compat";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IRefreshToken } from "../interfaces/IRefreshToken/IRefreshToken";

@Injectable({
  providedIn: 'root'
})

export class AuthService
{
  constructor(private http : HttpClient){ }

  CheckTokenIsValid(token: string) : Observable<boolean>
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`
    });

    return this.http
    .get<boolean>(`${environment.apiUrl}/api/auth`, { headers: headers })
    .pipe(map((isValid: boolean) => isValid));
  }

  RefreshToken(token: string) : Observable<IRefreshToken>
  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`
    });

    return this.http
    .get<IRefreshToken>(`${environment.apiUrl}/api/auth/refresh-token`, { headers: headers })
    .pipe(map((refreshToken: IRefreshToken) => refreshToken));
  }
}
