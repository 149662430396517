import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Webhook } from './interfaces/Webhook';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  private webhookDataSubject: BehaviorSubject<Webhook> = new BehaviorSubject<Webhook>(null);
  private webhookNotifyLogin = new BehaviorSubject<boolean>(false);

  constructor() { }

   // Método para atualizar os dados do webhook
   updateWebhookData(data: Webhook): void {
    this.webhookDataSubject.next(data);
  }

  // Método para obter os dados do webhook como um Observable
  getWebhookData(): Observable<any> {
    return this.webhookDataSubject.asObservable();
  }

  // Método para atualizar o login
  updateWebhookNotifyLogin(data: boolean): void {
    this.webhookNotifyLogin.next(data);
  }

  // Método para notificar o login
  getWebhookNotifyLogin(): Observable<any> {
    return this.webhookNotifyLogin.asObservable();
  }

}
